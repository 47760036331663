import plots from './plots.json'

let plot;

const path = window.location.search.replace('?/', '');
if (path.length === 36) {
    plot = plots.find(p => p.uuid === path);
}

if (!plot) {
   plot = plots[Math.floor(Math.random() * plots.length)]
}

document.querySelector('#movie-title').innerText = plot.name
document.querySelector('#movie-description').innerText = plot.plot
document.querySelector('#movie-image').setAttribute('src', plot.uuid + '.png')
document.querySelector('#bg-image').setAttribute('src', plot.uuid + '.png')
document.querySelector('#current-link').setAttribute('href', `/?/${plot.uuid}`);

for (const genre of [plot.year, ...plot.genres]) {
    const genreDiv = document.createElement('div');
    genreDiv.innerText = genre;
    document.querySelector('#movie-genres').appendChild(genreDiv)
}
